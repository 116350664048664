import styled from "@emotion/styled";
import { CardBody, CardHeader } from "@nextui-org/react";

const CardHeaderText = styled.h3({
  textAlign: "center",
  padding: "20px 20px 20px 20px"
});

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: "100%",
`;

const SearchCard = styled.div`
width: 70%;

@media (maxWidth: 1400px){
  width: 80%;
}

  @media (max-width: 1200px) {
    width: 90%;
  }
  `;

const CardFooterText = styled.p({
  textAlign: "center",
  padding: "10px 10px 10px 10px"
});

export { CardHeaderText, StyledCardHeader, StyledCardBody, SearchCard, CardFooterText };
