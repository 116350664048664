import React from "react";
import { Card, CardBody } from "@nextui-org/react";
import { capitalizeFirstLetter } from "../../../helpers/TextConverters";

const SearchInfoTable = ({
  search,
  filtertype,
  totalCount,
  genusCount,
  infraclassCount,
  usageCount,
  orderCount,
  familyCount,
  classCount,
  subclassCount,
  genusBool,
  infraclassBool,
  usageBool,
  orderBool,
  familyBool,
  classBool,
  subclassBool,
  order,
  family,
  chemical_class,
  chemical_subclass,
  partialBool,
  incorrectBool,
  anyFilter,
}) => {
  const displaySections = () => {
    let count = 0;

    if (genusBool) count++;
    if (infraclassBool) count++;
    if (usageBool) count++;
    if (orderBool) count++;
    if (familyBool) count++;
    if (classBool) count++;
    if (subclassBool) count++;

    return count >= 2;
  };

  const displayHierarchicalInfo = () => {
    if (incorrectBool) {
      return (
        <>
          <p className="text-sm/[20px]">
            Keyword not found. Returning all results
          </p>
          <p>
            <strong>{totalCount}</strong>{" "}
            {totalCount === 1 ? "result" : "results"} found
          </p>
        </>
      );
    } else if (partialBool) {
      return (
        <p>
          <strong>{totalCount}</strong>{" "}
          {totalCount === 1 ? "result" : "results"} found
        </p>
      );
    }
    let conditionFilter = filtertype;

    if (filtertype === "any" && anyFilter) {
      conditionFilter = anyFilter;
    }

    switch (conditionFilter) {
      case "genus":
        return (
          <>
            <p>Order: {order}</p>
            <p>Family: {family}</p>
            <hr />
            <p>
              <strong>{totalCount}</strong>{" "}
              {totalCount === 1 ? "result" : "results"} found
            </p>
          </>
        );
      case "plant_family":
        return (
          <>
            <p>Order: {order}</p>
            <hr />
            <p>
              <strong>{totalCount}</strong>{" "}
              {totalCount === 1 ? "result" : "results"} found
            </p>
          </>
        );
      case "infraclass":
        return (
          <>
            <p>Class: {chemical_class}</p>
            <p>Subclass: {chemical_subclass}</p>
            <hr />
            <p>
              <strong>{totalCount}</strong>{" "}
              {totalCount === 1 ? "result" : "results"} found
            </p>
          </>
        );
      case "chemical_subclass":
        return (
          <>
            <p>Class: {chemical_class}</p>
            <hr />
            <p>
              <strong>{totalCount}</strong>{" "}
              {totalCount === 1 ? "result" : "results"} found
            </p>
          </>
        );
      default:
        return (
          <p>
            <strong>{totalCount}</strong>{" "}
            {totalCount === 1 ? "result" : "results"} found
          </p>
        );
    }
  };

  return (
    <Card>
      <CardBody className="min-h-[496px] max-h-[496px] overflow-y-scroll">
        <center>
          <h4>
            <p>{capitalizeFirstLetter(filtertype.replace("_", " "))}</p>
          </h4>
          <h4>
            <p>
              Search result for '
              <i>
                {search === "No_keyword_entered"
                  ? ""
                  : search.replace(".", " ")}
              </i>
              '
            </p>
          </h4>
          <hr></hr>
          <h5>{displayHierarchicalInfo()}</h5>
          <h5>
            {displaySections() && (
              <>
                <hr></hr>
                {genusBool && (
                  <p>
                    <strong>{genusCount}</strong>{" "}
                    {genusCount === 1 ? "genus" : "genera"} found
                  </p>
                )}

                {infraclassBool && (
                  <p>
                    <strong>{infraclassCount}</strong>{" "}
                    {infraclassCount === 1 ? "infraclass" : "infraclasses"}{" "}
                    found
                  </p>
                )}

                {usageBool && (
                  <p>
                    <strong>{usageCount}</strong>{" "}
                    {usageCount === 1 ? "usage" : "usages"} found
                  </p>
                )}

                {orderBool && (
                  <p>
                    <strong>{orderCount}</strong>{" "}
                    {orderCount === 1 ? "order" : "orders"} found
                  </p>
                )}

                {familyBool && (
                  <p>
                    <strong>{familyCount}</strong>{" "}
                    {familyCount === 1 ? "family" : "families"} found
                  </p>
                )}

                {classBool && (
                  <p>
                    <strong>{classCount}</strong>{" "}
                    {classCount === 1 ? "class" : "classes"} found
                  </p>
                )}

                {subclassBool && (
                  <p>
                    <strong>{subclassCount}</strong>{" "}
                    {subclassCount === 1 ? "subclass" : "subclasses"} found
                  </p>
                )}
              </>
            )}
          </h5>
        </center>
      </CardBody>
    </Card>
  );
};
export default SearchInfoTable;
