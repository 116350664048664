import React, { useEffect, useState } from "react";
import { RequestFilterAPI } from "../../helpers/RequestFilterAPI";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import SearchResultHeader from "../SearchResultHeader/SearchResultHeader";
import SearchResultsTable from "./SearchResultsTable/SearchResultsTable";
import { useParams } from "react-router-dom";
import { SearchResultsContainer } from "./SearchResultsStyles";

const SearchResults = ({ theme, toggleTheme }) => {
  console.log("SearchResults Component Rendering");
  let { keyword, filter } = useParams();
  keyword = decodeURIComponent(keyword);
  filter = decodeURIComponent(filter);
  console.log(`keyword : ${keyword} and filter: ${filter}`);
  const [mapData, setMapData] = useState(null);
  const [progressBool, setProgressBool] = useState(true);
  const [errorBool, setErrorBool] = useState(false);
  const [partialBool, setPartialBool] = useState(false);
  const [fullScan, setFullScan] = useState(false);
  const [anyFilter, setAnyFilter] = useState(false);
  document.title = "CMFoA Search Results";

  useEffect(() => {
    setProgressBool(true);
    setErrorBool(false);
    setAnyFilter(false);
    setFullScan(false);
    setPartialBool(false);
    const fetchMapData = async () => {
      console.log("api", filter, keyword);
      const response = await RequestFilterAPI(keyword, filter);
      setMapData(response);
      setProgressBool(false);
      if (response.partial) {
        console.log("inside partial check");
        setPartialBool(true);
      } else if (response.allSearch) {
        setFullScan(true);
      } else if (filter === "any") {
        setAnyFilter(response.filter);
      }
    };
    fetchMapData();
  }, [keyword, filter]);

  console.log(
    `partial Bool: ${partialBool} and any bool: ${anyFilter} and fullScan : ${fullScan}`
  );

  console.log(`mapped Data: ${JSON.stringify(mapData)}`);
  return (
    <SearchResultsContainer>
      <SearchResultHeader theme={theme} toggleTheme={toggleTheme} />
      {progressBool && <Loading />}
      {errorBool && <Error />}
      {!progressBool && !errorBool && mapData && (
        <SearchResultsTable
          data={filter === "any" && partialBool ? mapData : mapData.Items}
          keyword={keyword}
          filter={filter}
          fullScanBool={fullScan}
          partialBool={partialBool}
          anyFilter={anyFilter}
          theme = {theme}
          toggleTheme = {toggleTheme}
        />
      )}
    </SearchResultsContainer>
  );
};

export default SearchResults;
