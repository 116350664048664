import styled from "@emotion/styled";

const CentredDiv = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

export { CentredDiv };
