import React, { useEffect } from "react";
import {
  SigmaContainer,
  useSigma,
  useLoadGraph,
  useRegisterEvents,
  ControlsContainer,
  ZoomControl,
  FullScreenControl,
  SearchControl,
} from "@react-sigma/core";
import { Graph as gph } from "graphology";
import { useNavigate } from "react-router-dom";

import "@react-sigma/core/lib/react-sigma.min.css";
import { NavigatePage } from "../../helpers/NavigatePage";

// define component for handling graph events (such as node clicks)
const GraphEvents = () => {
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();
  const navigate = useNavigate();
  const graph = sigma.getGraph();

  useEffect(() => {
    registerEvents({
      // Navigating to search results page for node when clicked
      clickNode: (event) => {
        const { node } = event;
        console.log(node.toLowerCase());
        NavigatePage(navigate, `/search/any/${node.toLowerCase()}`);
      },

      // Highlighting nodes and edges when hover
      enterNode: (event) => {
        const { node } = event;

        graph.forEachNode((n) => {
          if (n !== node) {
            graph.updateNode(n, (currentAttributes) => ({
              ...currentAttributes,
              originalColor: currentAttributes.color,
              color: "#D3D3D3",
              label: "",
            }));
          }
        });

        graph.forEachEdge((edge) => {
          const source = graph.source(edge);
          const target = graph.target(edge);
          if (source !== node && target !== node) {
            graph.updateEdge(source, target, (currentAttributes) => ({
              ...currentAttributes,
              originalColor: currentAttributes.color,
              color: "#D3D3D3",
            }));
          }
        });

        graph.updateNode(node, (currentAttributes) => ({
          ...currentAttributes,
          color: "FF0083",
          size: currentAttributes.size * 1.5,
          label: currentAttributes.originalLabel,
        }));

        graph.edges(node).forEach((edge) => {
          const source = graph.source(edge);
          const target = graph.target(edge);
          graph.updateEdge(source, target, (currentAttributes) => ({
            ...currentAttributes,
            color: "FF0083",
          }));

          if (target !== node) {
            graph.updateNode(target, (targetAttributes) => ({
              ...targetAttributes,
              color: "blue",
              label: targetAttributes.originalLabel,
            }));
          }
          if (source !== node) {
            graph.updateNode(source, (targetAttributes) => ({
              ...targetAttributes,
              color: "blue",
              label: targetAttributes.originalLabel,
            }));
          }
        });

        sigma.refresh();
      },

      // Setting nodes and edge colours back when hovered off
      leaveNode: () => {
        graph.forEachNode((node) => {
          graph.updateNode(node, (currentAttributes) => ({
            ...currentAttributes,
            color: currentAttributes.originalColor || currentAttributes.color,
            size: currentAttributes.originalSize || currentAttributes.size,
            label: currentAttributes.originalLabel,
          }));
        });

        graph.forEachEdge((edge) => {
          const source = graph.source(edge);
          const target = graph.target(edge);
          graph.updateEdge(source, target, (currentAttributes) => ({
            ...currentAttributes,
            color: "#8a8a8a",
          }));
        });

        sigma.refresh();
      },
    });
  }, [registerEvents, sigma]);

  return null;
};

// initializing the network diagram with nodes and edges from cmfoa.json
const GraphContents = () => {
  const loadGraph = useLoadGraph();

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "cmfoa.json")
      .then((response) => response.json())
      .then((data) => {
        const graph = new gph();

        data.nodes.forEach((node) => {
          const { label, x, y, size, color, mode } = node.attributes;
          graph.addNode(node.key, {
            label,
            x,
            y,
            size,
            color,
            mode,
            originalColor: color,
            originalSize: size,
            originalLabel: label,
          });
        });

        data.edges.forEach((edge) => {
          graph.addEdge(edge.source, edge.target, edge.attributes);
        });
        loadGraph(graph);
      });
  }, [loadGraph]);

  return null;
};

// displaying network diagram with interface for controls
const Graph = () => {
  return (
    <div>
      <SigmaContainer
        style={{ width: "100vw", height: "600px" }}
        settings={{
          drawEdges: true,
          drawNodes: true,
          drawLabels: true,
          labelAlignment: "center",
          labelThreshold: 0,
          enableHovering: true,
        }}
      >
        <GraphContents />
        <GraphEvents />
        <ControlsContainer position={"top-right"}>
          <ZoomControl />
          <FullScreenControl />
        </ControlsContainer>
        <ControlsContainer position={"bottom-right"}>
          <SearchControl style={{ width: "200px" }} />
        </ControlsContainer>
      </SigmaContainer>
    </div>
  );
};

export default Graph;
