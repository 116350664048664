import React from "react";
import { Logo, StyledHeader } from "./HeaderSectionStyles";
import logo from "../../assets/LogoWhite_resized.png";
import darkLogo from "../../assets/LogoBlack_resized.png";
import { Link } from "@nextui-org/react";

const HeaderSection = ({ theme }) => {
  const logoSource = theme === "dark" ? darkLogo : logo;
  return (
    <StyledHeader>
      <Link href={`/graph`}><Logo src={logoSource} alt="Customary Flora of Australia Logo" /></Link>
    </StyledHeader>
  );
};

export default HeaderSection;
