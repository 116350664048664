import SearchResultHeader from "../SearchResultHeader/SearchResultHeader";
import Graph from "../Graph/Graph";
import {
  CardWrapper,
  GraphButtonContainer,
  GraphContainer,
  GraphItem,
} from "./Graph.styles";
import { Card } from "@nextui-org/react";
import {
  CardHeaderText,
  StyledCardBody,
  StyledCardHeader,
} from "../SearchPanel/SearchPanelStyles";

const GraphPanel = ({ theme, toggleTheme }) => {
  document.title = "CMFoA Network Diagram";
  return (
    <div>
      <SearchResultHeader theme={theme} toggleTheme={toggleTheme} />
      <CardHeaderText>
        Graph of Plant and Compound Class Relationships
      </CardHeaderText>
      <GraphContainer>
        <GraphItem>
          <Graph />
        </GraphItem>
      </GraphContainer>
      <div>
        <GraphButtonContainer>
          {/* <CardWrapper>
            <Card
              isBlurred
              shadow="sm"
              className="border-none bg-background/60 dark:bg-default-100/50"
            >
              <StyledCardHeader>
                <CardHeaderText>Graph Description</CardHeaderText>
              </StyledCardHeader>
              <StyledCardBody>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent vitae sollicitudin ante. Quisque luctus erat nunc, at
                  consequat dui scelerisque at. Interdum et malesuada fames ac
                  ante ipsum primis in faucibus. Nulla nec hendrerit eros. In
                  congue condimentum orci nec mollis. Pellentesque non varius
                  erat. Phasellus bibendum dapibus velit, nec viverra turpis
                  rhoncus sed. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Vivamus iaculis
                  facilisis lorem quis pulvinar. Duis interdum viverra sem,
                  condimentum vehicula sem pretium vel. Aliquam non efficitur
                  ligula.{" "}
                </p>
              </StyledCardBody>
            </Card>
          </CardWrapper> */}
          <CardWrapper>
            <Card
              isBlurred
              shadow="sm"
              className="border-none bg-background/60 dark:bg-default-100/50"
            >
              <StyledCardHeader>
                <CardHeaderText>Graph Information</CardHeaderText>
              </StyledCardHeader>
              <StyledCardBody>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#00aa72",
                      marginRight: "8px",
                    }}
                  ></div>
                  Plants
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#ff6078",
                      marginRight: "8px",
                    }}
                  ></div>
                  Compound Classes
                </div>
                Nodes: 354 <n></n>Edges: 1528
              </StyledCardBody>
            </Card>
          </CardWrapper>
        </GraphButtonContainer>
      </div>
    </div>
  );
};

export default GraphPanel;
