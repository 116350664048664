import SearchPanel from "../SearchPanel/SearchPanel";
import HeaderSection from "../HeaderSection/HeaderSection";
import { CentredDiv } from "./Home.styles";

const Home = ({ theme, toggleTheme }) => {
  document.title = "CMFoA";
  return (
    <CentredDiv>
      <HeaderSection theme={theme} />
      <SearchPanel toggleTheme={toggleTheme} />
    </CentredDiv>
  );
};

export default Home;
