import styled from "@emotion/styled";

const ResultStyle = {
  tableContainer: {
    width: "35%",
    alignItems: "center",
    padding: "0.625rem",
    paddingLeft: "14%",
  },

  tabStyles: {
    width: "70%",
    paddingRight: "14%",
  },
};

const SearchResultsContainer = styled.div`
  height: 100vh;
`;

export { ResultStyle, SearchResultsContainer };
