export const ItemMapping = (
  data,
  filter,
  partialBool,
  fullscanBool,
  anyFilter
) => {
  // parsing data based on search result type
  if (partialBool) {
    return partialMapping(data, filter);
  } else if (fullscanBool) {
    return fullScanMapping(data, filter);
  } else {
    return singleFilterMapping(data, filter, anyFilter);
  }
};

// Extracting unique values from data
const extractUniqueValues = (data, attribute) => {
  const result = new Set();
  switch (attribute) {
    case "Genus":
      data.forEach((item) => {
        if (item.PK && item.PK.startsWith("Genus#")) {
          result.add(item.PK.replace(/^Genus#/, ""));
        }
      });
      return [...result];
    case "Infraclass":
      data.forEach((item) => {
        if (item.SK && item.SK.startsWith("Infraclass#")) {
          result.add(item.SK.replace(/^Infraclass#/, ""));
        }
      });
      return [...result];
    case "Usage":
      data.forEach((item) => {
        if (item.SK && item.SK.startsWith("Usage#")) {
          result.add(item.SK.replace(/^Usage#/, ""));
        }
      });
      return [...result];
    default:
      data.forEach((item) => {
        if (item[attribute] !== undefined) {
          result.add(item[attribute]);
        }
      });
      return [...result];
  }
};

// Default filter used for parsing data into item mapping
const defaultFilter = (data, attributes) =>
  attributes.reduce((result, attr) => {
    result[attr] = extractUniqueValues(data, attr);
    return result;
  }, {});

// Defining functions for complete keyword searches based on different filters
// Note: 'any' key in filterFunctions is also used for incorrect any search
const filterFunctions = {
  genus: (data) => defaultFilter(data, ["Infraclass", "Usage", "Family", "Order"]),
  infraclass: (data) => defaultFilter(data, ["Genus", "Class", "Subclass", "Group", "Subgroup"]),
  customary_use: (data) => defaultFilter(data, ["Genus"]),
  plant_order: (data) => defaultFilter(data, ["Genus", "Infraclass", "Usage", "Family"]),
  plant_family: (data) => defaultFilter(data, ["Genus", "Infraclass", "Usage", "Order"]),
  chemical_class: (data) => defaultFilter(data, ["Infraclass", "Genus", "Subclass"]),
  chemical_subclass: (data) => defaultFilter(data, ["Infraclass", "Genus", "Class"]),
  any: (data) =>
    defaultFilter(data, [
      "Genus",
      "Infraclass",
      "Usage",
      "Order",
      "Family",
      "Class",
      "Subclass",
    ]),
};

// Function for complete keyword search mapping
const singleFilterMapping = (data, filterName, anyFilter) => {
  let result = {};
  if (filterName === "any" && anyFilter) {
    const anyFilterFunction = filterFunctions[anyFilter];
    if (anyFilterFunction) {
      result.filter = anyFilter;
      Object.assign(result, anyFilterFunction(data));
    }
  } else {
    Object.assign(result, filterFunctions[filterName](data));
  }
  return result;
};

// Defining functions for partial keyword searches based on different filters
// Note: partialFilterFunctions also defines filters for incorrect filtered searches
const partialFilterFunctions = {
  genus: (data) => defaultFilter(data, ["Genus"]),
  infraclass: (data) => defaultFilter(data, ["Infraclass"]),
  customary_use: (data) => defaultFilter(data, ["Usage"]),
  plant_order: (data) => defaultFilter(data, ["Order"]),
  plant_family: (data) => defaultFilter(data, ["Family"]),
  chemical_class: (data) => defaultFilter(data, ["Class"]),
  chemical_subclass: (data) => defaultFilter(data, ["Subclass"]),
};

// use this mapping for partial keyword search results
const partialMapping = (data, filterName) => {
  const result = {};
  const partialData = Object.keys(data)
    .filter((key) => key !== "partial") // Exclude the 'partial' property
    .map((key) => data[key]);
  if (filterName === "any") {
    // iterate through each possible filter for partial results
    partialData.forEach((obj) => {
      const itrFilter = obj.filter;
      Object.assign(result, partialFilterFunctions[itrFilter](obj.Items));
    });
  } else {
    Object.assign(result, partialFilterFunctions[filterName](partialData));
  }
  return result;
};

// use for incomplete keyword search results
const fullScanMapping = (data, filter) => {
  const result = {};
  if (filter === "any") {
    return Object.assign(result, filterFunctions[filter](data));
  } else {
    return Object.assign(result, partialFilterFunctions[filter](data));
  }
};
