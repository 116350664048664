import styled from "@emotion/styled";
import {
  Input
} from "@nextui-org/react";

const DropdownWrapper = styled.div`
  width: 70% !important;
  
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const SearchInput = styled(Input)`
flex: 1;
width: 100%;
`

export { DropdownWrapper, InputWrapper, SearchInput };
