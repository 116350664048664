import SearchResults from "../components/SearchResults/SearchResults";
import { useParams } from "react-router-dom";
export const NavigatePage = (navigate, route) => {
  return navigate(route);
};

export const SearchResultsWrapper = ({ theme, toggleTheme }) => {
  const { filter, keyword } = useParams();
  const key = `${filter}-${keyword}`;
  return <SearchResults key={key} theme={theme} toggleTheme={toggleTheme} />;
};
