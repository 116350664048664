import React from "react";
import { Tabs, Tab, Link } from "@nextui-org/react";
import {
  Table,
  TableBody,
  TableHeader,
  TableColumn,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { ResultStyle } from "../SearchResultsStyles";
import SearchInfoTable from "../SearchInfoTable/SearchInfoTable";
import { ItemMapping } from "../../../utils/itemMapping";
import {
  removeFullStops,
  getKeywordCase,
} from "../../../helpers/TextConverters";

const SearchResultsTable = ({
  data: dataMapped,
  keyword,
  filter,
  partialBool,
  fullScanBool,
  anyFilter,
  theme,
  toggleTheme,
}) => {
  const search = getKeywordCase(keyword); // Convert keyword to a consistent case

  // Setting filter names and their corresponding capital case names (used for structure of item mapping)
  const filterSettings = {
    genus: "Genus",
    infraclass: "Infraclass",
    customary_use: "Usage",
    plant_family: "Family",
    plant_order: "Order",
    chemical_class: "Class",
    chemical_subclass: "Subclass",
  };

  // Initializing Map structures to store booleans, counts, and items from results data
  const bools = new Map(
    Object.values(filterSettings).map((value) => [value, false])
  );
  const counts = new Map(
    Object.values(filterSettings).map((value) => [value, 0])
  );
  const items = new Map(
    Object.values(filterSettings).map((value) => [value, []])
  );

  let itemsFound = 0; // Initializing total item count value

  // Initializing resultant data to be shown based on search filter used
  const completeKeywordTabs = {
    genus: { tabs: ["Infraclass", "Usage"], info: ["Order", "Family"] },
    infraclass: { tabs: ["Genus"], info: ["Class", "Subclass"] },
    customary_use: { tabs: ["Genus"], info: [] },
    plant_order: { tabs: ["Genus", "Infraclass", "Usage", "Family"], info: [] },
    plant_family: { tabs: ["Genus", "Infraclass", "Usage"], info: ["Order"] },
    chemical_class: { tabs: ["Genus", "Infraclass", "Subclass"], info: [] },
    chemical_subclass: { tabs: ["Genus", "Infraclass"], info: ["Class"] },
  };

  // Parsing data from API call into cleaner results
  dataMapped = ItemMapping(
    dataMapped,
    filter,
    partialBool,
    fullScanBool,
    anyFilter
  );

  console.log(`Line 67: ${JSON.stringify(dataMapped)}`);

  // Function to set result data based on filter name and data array
  const setFilterData = (key, data) => {
    items.set(key, data);
    bools.set(key, true);
    counts.set(key, data.length);
  };

  // Handle partial or incorrect keyword searches (incorrect keyword returns full scan)
  if (partialBool || fullScanBool) {
    // Iterating through parsed item mapping
    Object.keys(dataMapped).forEach((key) => {
      setFilterData(key, dataMapped[key]);
      itemsFound += counts.get(key);
    });
  } else {
    // Handle complete keyword searches
    const filterName = filter === "any" ? dataMapped.filter : filter; // use internal filter if "any" is used
    // Iterating through parsed item mapping for result table data
    completeKeywordTabs[filterName].tabs.forEach((key) => {
      setFilterData(key, dataMapped[key]);
      itemsFound += counts.get(key);
    });

    // Iterating through parsed item mapping for side panel hierarchical information
    completeKeywordTabs[filterName].info.forEach((key) => {
      items.set(key, dataMapped[key]);
    });
  }

  // storing information into tables (using dynamic tabs)
  const tabs = Object.entries(filterSettings)
    // key is lowercase filter names, value is capital case filter names
    .map(([key, value]) => {
      if (bools.get(value)) {
        return {
          // key needs to be formatted
          label: key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          items: items.get(value).sort((a, b) => a.localeCompare(b)),
          count: counts.get(value),
        };
      }
      return null;
    })
    .filter(Boolean);

  // Define the highlighting colors based on the theme
  console.log(theme);
  const highlightColor = theme === "dark" ? "warning" : "primary";

  return (
    <div style={{ display: "flex" }}>
      <div style={ResultStyle.tableContainer}>
        <SearchInfoTable
          search={search}
          filtertype={filter}
          totalCount={itemsFound}
          genusCount={counts.get("Genus")}
          infraclassCount={counts.get("Infraclass")}
          usageCount={counts.get("Usage")}
          orderCount={counts.get("Order")}
          familyCount={counts.get("Family")}
          classCount={counts.get("Class")}
          subclassCount={counts.get("Subclass")}
          genusBool={bools.get("Genus")}
          infraclassBool={bools.get("Infraclass")}
          usageBool={bools.get("Usage")}
          orderBool={bools.get("Order")}
          familyBool={bools.get("Family")}
          classBool={bools.get("Class")}
          subclassBool={bools.get("Subclass")}
          order={items.get("Order")}
          family={items.get("Family")}
          chemical_class={items.get("Class")}
          chemical_subclass={items.get("Subclass")}
          partialBool={partialBool}
          incorrectBool={fullScanBool}
          anyFilter={anyFilter}
        />
      </div>

      {/* Search Result Tables (with tab select) */}
      <div className="flex flex-col" style={ResultStyle.tabStyles}>
        <Tabs aria-label="Dynamic tabs">
          {tabs
            .filter((tab) => tab.count > 0) // show tabs based on search results returned
            .map((tab) => (
              <Tab title={tab.label}>
                <Table
                  hideHeader
                  classNames={{
                    base: "max-h-[450px] ",
                    table: "min-h-[450px] overflow-y-scroll",
                  }}
                >
                  <TableHeader>
                    <TableColumn>{filter}</TableColumn>
                  </TableHeader>
                  <TableBody>
                    {tab.items.map((item) => {
                      return (
                        <TableRow key={item}>
                          <TableCell>
                            <Link
                              color={highlightColor}
                              isBlock
                              href={`/search/${tab.label
                                .toLowerCase()
                                .replace(/ /g, "_")}/${encodeURIComponent(
                                item
                              ).toLowerCase()}`}
                            >
                              {removeFullStops(item)}
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Tab>
            ))}
        </Tabs>
      </div>
    </div>
  );
};

export default SearchResultsTable;
