import React from "react";
import SearchBox from "../SearchPanel/SearchBox/SearchBox";
import logo from "../../assets/LogoWhite_resized.png";
import darkLogo from "../../assets/LogoBlack_resized.png";
import { useNavigate } from "react-router-dom";
import { NavigatePage } from "../../helpers/NavigatePage";
/*import { Link } from "@nextui-org/react";
import { CardFooterText } from "../SearchPanel/SearchPanelStyles";*/

const SearchResultHeader = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const logoSource = theme === "dark" ? darkLogo : logo;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        gap: "30px",
      }}
    >
      <div
        style={{
          borderRadius: "10%",
          overflow: "hidden",
          margin: "5px",
        }}
        onClick={() => NavigatePage(navigate, "/")}
      >
        <img
          src={logoSource}
          alt="Customary Flora of Australia Logo"
          style={{ width: "200px", height: "auto" }}
        />
      </div>
      <SearchBox toggleTheme={toggleTheme} />
    </div>
  );
};

export default SearchResultHeader;
