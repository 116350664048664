import { getKeywordCase } from "./TextConverters";
export const RequestFilterAPI = async (keyword, filter) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      keyword: getKeywordCase(keyword),
      filter: filter,
    }),
  };

  try {
    console.log(requestOptions);
    const response = await fetch(
      "https://0b4knsoz12.execute-api.ap-southeast-2.amazonaws.com/",
      requestOptions
    );
    return await response.json();
  } catch (error) {
    console.error("There has been an error in fetching data", error);
    throw error;
  }
};
