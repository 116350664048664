export const removeFullStops = (str) => {
  const regex = new RegExp("\\.", "g");
  return str.replace(regex, " ");
};

export const addFullStop = (str) => {
  const trimmedStr = str.trim();
  const regex = new RegExp(" ", "g");
  const result = trimmedStr.replace(regex, ".");
  return result;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getKeywordCase = (key) => {
  return key.split(".").map(capitalizeFirstLetter).join(".");
};
