import styled from "@emotion/styled";

const StyledHeader = styled.div`
  padding: 20px;
  h1 {
    color: #333;
  }
`;

const Logo = styled.img({
  width: "550px",
});

export { StyledHeader, Logo };
