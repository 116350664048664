import React from "react";
import { Card, CardBody, CardHeader, Progress } from "@nextui-org/react";
import { CardHeaderText } from "../SearchPanel/SearchPanelStyles";
import { CentreCard } from "./Loading.styles";

const Loading = () => {
  return (
    <CentreCard>
      <Card>
        <CardHeader>
          <CardHeaderText>Fetching Data</CardHeaderText>
        </CardHeader>
        <CardBody>
          <Progress isIndeterminate size="sm" />
        </CardBody>
      </Card>
    </CentreCard>
  );
};

export default Loading;
