import styled from "@emotion/styled";

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

const GraphItem = styled.div`
  display: inline-block;
`;

const GraphButtonContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`;

const CardWrapper = styled.div`
  margin: 10px;
  max-width: 1000px;
  flex: 1;
  min-height: 600px;
`;

export { GraphContainer, GraphButtonContainer, GraphItem, CardWrapper };
