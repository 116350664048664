import React from "react";
import SearchBox from "./SearchBox/SearchBox";
import { Card, Link } from "@nextui-org/react";
import {
  CardHeaderText,
  StyledCardBody,
  SearchCard,
  CardFooterText,
} from "./SearchPanelStyles";

const SearchPanel = ({ toggleTheme }) => {
  return (
    <SearchCard>
      <Card
        isBlurred
        shadow="sm"
        className="border-none bg-background/60 dark:bg-default-100/50"
      >
        <StyledCardBody>
          <CardHeaderText>
            Customary Medicinal Flora of Australia
          </CardHeaderText>
          <SearchBox toggleTheme={toggleTheme} />
          <CardFooterText>
            <Link href={`/graph`}>view the network diagram</Link>
          </CardFooterText>
        </StyledCardBody>
      </Card>
    </SearchCard>
  );
};
export default SearchPanel;
